<template>
  <div class="guid_read container">
    <div class="title">
      <h4>指南解读</h4>
      <nuxt-link target="_blank"
        :to="{ path: '/guide', query: { deptID: $route.query.deptID } }"
      >
        <button class="check_all">查看全部</button>
      </nuxt-link>
    </div>
    <div class="guid_content">
      <ul>
        <li class="hand" v-for="(item, index) in info" :key="index">
          <nuxt-link target="_blank"
            :to="{
              path: '/detailPage',
              query: { articleId: item.articleId },
            }"
          >
            <h4>
              {{ item.title }}
            </h4>
          </nuxt-link>
          <div class="news-tag">
            <!-- <div class="tags">
              <span
                class="tag"
                v-for="(tag, index) in item.tagList"
                :key="index"
                @click="toTagPage(tag)"
                >{{ tag.name | chinese }}</span
              >
            </div> -->
            <div class="time_pv">
              <i class="clock"></i>
              <span class="time">{{ item.publishTime | timeFormater }}</span>
              <!-- <i v-if="item.pv > 0" class="eye"></i>
              <span v-if="item.pv > 0" class="views">{{
                item.pv | viewsFormatter
              }}</span> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dateformat from "dateformat";
export default {
  props: {
    info: {
      type: Array
    }
  },
  methods: {
    toTagPage(item) {
      // console.log(item)
      // tagPage ? name = 脂质和代谢 & id=90 & deptID=96101
      this.$router.push({
        path: '/new',
        query: {
          moduleName: "LATEST_NEWS",
          name: item.name,
          id: item.termId,
          deptID: item.departmentId
        }
      })
    }
  },
  filters: {
    // 时间格式化
    timeFormater(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
    // pv格式化
    viewsFormatter(pv) {
      if (pv > 10000) {
        let w = pv / 10000
        let q = (pv % 1000) / 1000
        return `${w}.${q}万`
      } else {
        return pv
      }
    },
    chinese(v) {
      if (v.length > 7) {
        return v.substring(0, 7) + '...'
      } else {
        return v
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.guid_read {
  background: #fff;
  // background: red;
  margin-bottom: 19px;
  max-height: 323px;
  .title {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      height: 46px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 46px;
    }
    .check_all {
      height: 31px;
      font-size: 15px;

      font-weight: 500;
      color: #333;
      line-height: 31px;
    }
  }
  .guid_content {
    ul {
      &::after {
        content: "";
        display: block;
        clear: both;
      }
      li {
        &:hover {
          box-shadow: 0px 0px 15px 0px #f1f1f1;
          background-color: #fff;
          color: #d70c19;
        }
        float: left;
        width: 410px;
        height: 97px;
        border-radius: 2px;
        background-color: #fafafa;
        padding: 10px 20px 10px 20px;
        margin-right: 34px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        h4 {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }
        .news-tag {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 22px;
          width: 100%;
          // margin-right: ;
          .tags {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          .tag {
            display: block;
            padding: 0 6px;
            height: 22px;
            background: #f2f2f2;
            border-radius: 2px;
            font-size: 12px;

            font-weight: 400;
            color: #a7a7a7;
            line-height: 22px;
            margin: 0 5px;
          }
          .tag:nth-child(1) {
            margin-left: 1px;
          }
          .time_pv {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .clock {
            display: block;
            width: 14px;
            height: 15px;
            background-image: url("../../assets/images/yxj_home/clock@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 4px;
          }
          .time {
            height: 22px;
            font-size: 12px;

            font-weight: 400;
            color: #a8a8a8;
            line-height: 22px;
            margin-right: 20px;
          }
          .eye {
            display: block;
            width: 16px;
            height: 12px;
            background-image: url("../../assets/images/yxj_home/chakan@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 4px;
          }
          .views {
            height: 22px;
            font-size: 12px;

            font-weight: 400;
            color: #a8a8a8;
            line-height: 22px;
          }
        }
      }
      li:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>