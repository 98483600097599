<template>
  <div class="advertisement">
    <div class="content container">
      <a :href="info1 && info1.link" target="_blank">
        <img class="ad-1" v-lazy="info1 && info1.coverUrl" alt="" />
      </a>
      <a :href="info2 && info2.link" target="_blank">
        <img class="ad-2" v-lazy="info2 && info2.coverUrl" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info1: {
      type: Object,
    },
    info2: {
      type: Object,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.advertisement {
  width: 100%;
  background-color: #fff;
  // margin: 15px 0;
  margin-bottom: 25px;
  .content {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a:nth-child(1) {
      width: 65.3%;
      margin-right: 3%;
      overflow: hidden;
    }
    a:nth-child(2) {
      width: 30.76%;
      overflow: hidden;
    }
    img {
      transition: all 0.3s;
    }
    img:hover {
      transform: scale(1.02, 1.02);
    }
    .ad-1 {
      width: 100%;
      height: 84px;
    }
    .ad-2 {
      width: 100%;
      height: 84px;
    }
  }
}
</style>