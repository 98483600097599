<template>
  <div class="brand_meet container">
    <div class="title">
      <h4>品牌会议</h4>
      <!-- <button class="check_all">查看全部</button> -->
    </div>
    <div class="meet_content">
      <ul>
        <li class="hand" v-for="(item, index) in info" :key="index">
          <div class="img">
            <img v-lazy="item.imgUlr" alt="" />
          </div>
          <div class="detail_content">
            <h4 class="line1">{{ item.title }}</h4>
            <p class="line2">{{ item.detail }}</p>
            <div class="news-tag">
              <button @click="showDetail">查看详情</button>
              <div class="right_icon">
                <i class="clock"></i>
                <span class="time">7月8日~7月16日</span>
                <i class="loc_icon"></i>
                <span class="location">上海</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => {
        return [
          {
            imgUlr: 'http://studioyszimg.yxj.org.cn/1627562855452.png',
            title: '不同化疗药物/方案致 CIT 的风险如何？',
            detail: '瑞格列奈是一种常用的非磺脲类胰岛素促泌剂作用于胰岛β细胞上AT依赖K',
            time: [1627465782, 1627465782],
            location: '上海',
            statu: 0
          },
          {
            imgUlr: 'http://studioyszimg.yxj.org.cn/1627562855452.png',
            title: '不同化疗药物/方案致 CIT 的风险如何？',
            detail: '瑞格列奈是一种常用的非磺脲类胰岛素促泌剂作用于胰岛β细胞上AT依赖K',
            time: [1627465782, 1627465782],
            location: '上海',
            statu: 1
          },
          {
            imgUlr: 'http://studioyszimg.yxj.org.cn/1627562855452.png',
            title: '不同化疗药物/方案致 CIT 的风险如何？',
            detail: '瑞格列奈是一种常用的非磺脲类胰岛素促泌剂作用于胰岛β细胞上AT依赖K',
            time: [1627465782, 1627465782],
            location: '上海',
            statu: 1
          },
        ]
      },
    }
  },
  methods: {
    // 跳会议外链
    showDetail() {
      console.log('查看详情');
    },
  }
}
</script>

<style lang="scss" scoped>
.brand_meet {
  background: #fff;
  margin-bottom: 40px;
  .title {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      height: 46px;
      font-size: 24px;

      font-weight: 500;
      color: #333333;
      line-height: 46px;
    }
    .check_all {
      height: 31px;
      font-size: 15px;

      font-weight: 500;
      color: #4a4a4a;
      line-height: 31px;
    }
  }
  .meet_content {
    height: 379px;
    ul {
      display: flex;
      justify-content: space-between;
    }
    ul li {
      box-sizing: content-box;
      width: 410px;
      height: 379px;
      float: left;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &:hover {
        box-shadow: 0px 0px 15px 0px rgba(220, 220, 220, 0.5);
      }
      .img {
        width: 100%;
        height: 183px;
        overflow: hidden;
        img {
          width: 410px;
          height: 100%;
        }
        img {
          transition: all 0.5s;
        }
        img:hover {
          transform: scale(1.1, 1.1);
        }
      }
      .detail_content {
        width: 100%;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #f0f0f0;
        h4 {
          width: 369px;
          height: 22px;
          margin: 25px 0 19px 0;
          font-size: 19px;

          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
        p {
          height: 52px;
          line-height: 26px;
          margin-bottom: 17px;
          width: 369px;
          font-size: 14px;

          font-weight: 400;
          color: #4a4a4a;
        }
        .news-tag {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 34px;
          width: 369px;
          button {
            width: 95px;
            height: 34px;
            background: #d70c19;
            border-radius: 4px;
            font-size: 16px;

            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
          }
          .right_icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .clock {
              display: block;
              width: 14px;
              height: 15px;
              background-image: url("../../assets/images/yxj_home/clock@2x.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 6px;
            }
            .time {
              height: 22px;
              font-size: 12px;

              font-weight: 400;
              color: #a8a8a8;
              line-height: 22px;
              margin-right: 39px;
            }
            .loc_icon {
              display: block;
              width: 13px;
              height: 16px;
              background-image: url("../../assets/images/yxj_home/location.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 6px;
            }
            .location {
              height: 22px;
              font-size: 12px;

              font-weight: 400;
              color: #a8a8a8;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
</style>