<template>
  <div class="depart">
    <!-- 栏目标题 -->
    <div class="title">
      <h5>{{ data.title }}</h5>
      <div class="check_all" @click="checAll(data.title)">
        <span class="text hand">查看全部</span><span class="icon"></span>
      </div>
    </div>

    <!-- 资讯内容 -->
    <div class="news_content">
      <div class="left">
        <div
          class="news hand"
          v-for="item in data.hotNewsList"
          :key="item.articleId"
          @click="goDetail(item.articleId)"
        >
          <div class="img">
            <img class="scale" :src="item.coverPicUrl" alt="" />
          </div>
          <div class="desc">
            <div class="news_title line_two hoverRed">
              {{ item.title }}
            </div>
            <div class="time">{{ item.publishTime | formatTime }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div
          class="right_news line_one hand hoverRed"
          v-for="item in data.newsList"
          :key="item.articleId"
          @click="goDetail(item.articleId)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateformat from "dateformat";
import { mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  filters: {
    formatTime(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
  },
  methods: {
    goDetail(articleId) {
      this.$router.push({ path: '/detailPage', query: { articleId } })
    },
    checAll(title) {
      this.$router.push({ path: '/articelList', query: { title, postition: '1', deptID: this.department ? this.department.dptid : 0 } })
    },
  },
  computed: {
    ...mapGetters(['department'])
  },

}
</script>

<style lang="scss" scoped>
.depart {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 913px;
    line-height: 38px;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 18px;
    h5 {
      height: 30px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 30px;
    }
    .check_all {
      height: 19px;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      line-height: 19px;
      display: flex;
      align-items: center;
      .text {
        margin-right: 2px;
      }
      .icon {
        display: block;
        width: 15px;
        height: 15px;
        background: url("../../../assets/images/yxj_home/checkAll.png")
          no-repeat;
        background-size: contain;
      }
    }
  }
  .news_content {
    height: 230px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 472px;
      height: 230px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .news {
        width: 100%;
        height: 105px;
        display: flex;
        justify-content: space-between;
        .img {
          width: 140px;
          height: 105px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .desc {
          width: 316px;
          height: 105px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .news_title {
            width: 316px;
            height: 60px;
            line-height: 30px;
            font-size: 18px;
            font-weight: 600;
            color: #333333;
            margin-top: -6px;
          }
          .time {
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
          }
        }
      }
    }
    .right {
      width: 417px;
      height: 230px;
      overflow: hidden;
      .right_news {
        width: 417px;
        height: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 22px;
        &:first-child {
          height: 24px;
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          line-height: 24px;
          margin-top: -3px;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>