<template>
  <div id="hot_news" title="资讯热榜" class="link_title border container">
    <div class="border_box">
      <div class="box_left">
        <el-carousel
          type="card"
          height="412px"
          style="width: 1368px; transform: translateX(-227.5px)"
          :autoplay="false"
        >
          <el-carousel-item
            v-for="(item, index) in topNewsList"
            :key="item.id"
            :id="index"
          >
            <div class="img hand" @click="goDetail(item.articleId)">
              <img
                v-lazy="item.coverPicUrl + '?timestamp=' + new Date().getTime()"
                alt=""
              />
              <div class="title_desc">
                <div class="top_title" v-if="item.title">{{ item.title }}</div>
                <!-- <div class="desc" v-if="item.brief">{{ item.brief }}</div> -->
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <div class="hot_one hand" @click="goDetail(topNewsList[0].articleId)">
          <div class="img">
            <img
              class="scale"
              v-lazy="
                topNewsList[0] &&
                topNewsList[0].coverPicUrl +
                  '?timestamp=' +
                  new Date().getTime()
              "
              alt=""
            />
          </div>
          <div class="desc">
            <div class="title line_two hoverRed">
              {{ topNewsList[0] && topNewsList[0].title }}
            </div>
            <div class="author">
              原创：<span>{{ topNewsList[0] && topNewsList[0].source }}</span>
            </div>
            <div class="detail line_three">
              {{ topNewsList[0] && topNewsList[0].brief }}
            </div>
          </div>
        </div> -->
        <!-- <div class="list">
          <div
            class="news hand"
            v-for="item in bottomNewsList"
            :key="item.articleId"
            @click="goDetail(item.articleId)"
          >
            <div class="content">
              <i></i>
              <div class="title line_one hoverRed">
                {{ item && item.title }}
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="box_right">
        <h3 style="display: flex; align-items: center">
          <img src="../../assets/images/yxj_home/home_hot.png" alt="" />
          <div class="right_title">24h最热</div>
        </h3>
        <div v-for="(item, i) in hotList" :key="'hotList' + i">
          <div class="right_flex">
            <div :class="color[i]" class="flex_num">{{ i + 1 }}</div>
            <nuxt-link
              target="_blank"
              class="flex_title"
              :to="{
                path: '/detailPage',
                query: { articleId: item.articleId },
              }"
            >
              {{ item.title }}
            </nuxt-link>
            <!-- <div v-if="i < 3" class="hot">热</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import consultList from '../home/consultList'
import dateformat from "dateformat";

export default {
  props: {
    topNewsList: {
      type: Array,
      default: () => []
    },
    hotList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      show: false,
      carouseId: 0,
      color: [
        'one',
        'two',
        'three'
      ],
      // newsList: [],
      // newsOne: [],
      // newsTwo: [],
      // newsThree: [],
      // hotList: []
    }
  },
  mounted() {
    // this.newsData()
    // this.hotNews()
    console.log(this.topNewsList, '轮播图数据');
    // this.setPY()
  },
  computed: {
    bottomNewsList() {
      return this.topNewsList.slice(1, 5)
    },
  },
  methods: {
    goDetail(articleId) {
      console.log(articleId, 'articleId');
      this.$router.push({ path: '/detailPage', query: { articleId } })
    },
    check(v) {
      if (v == 1) {
        this.show = false
      } else {
        this.show = true
      }
    },
    async newsData() {
      try {
        var data = await this.$axios.$post('/ysz-content/web/home/news/queryFeedNewsByPage', {
          "pageNum": 1,
          "pageSize": 19
        })
        this.newsList = data.body.data
        console.log("数据", this.newsList);

        this.newsOne = this.newsList.slice(0, 3)
        this.newsTwo = this.newsList.slice(3, 11)
        this.newsThree = this.newsList.slice(11, 20)
      } catch (error) {
        console.log(error)
      }
    },
    async hotNews() {
      try {
        var data = await this.$axios.$post('/ysz-content/web/home/news/queryHotNewsList')
        this.hotList = data.body.news
      } catch (error) {
        console.log(error)
      }
    }
  },
  filters: {
    // 时间格式化
    timeFormater(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
  },
  components: { consultList },
}
</script>

<style lang="scss" scoped>
.border {
  .border_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 40px;
    .box_left {
      width: 913px;
      overflow: hidden;
      /deep/.el-carousel {
        position: relative;
        width: 100%;
        height: 412px;
        .el-carousel__container {
          width: 100%;
          height: 100%;
          .img {
            position: relative;
            width: 100%;
            height: 412px !important;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
            .title_desc {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 180px;
              padding-left: 24px;
              padding-right: 24px;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #000000 100%
              );
              .top_title {
                line-height: 40px;
                color: #fff;
                font-size: 30px;
                font-weight: 600;
                margin-top: 54px;
                margin-bottom: 14px;
              }
              .desc {
                width: 100%;
                font-size: 18px;
                font-weight: 400;
                color: #ffffff;
                line-height: 22px;
              }
            }
          }
        }
        .el-carousel__arrow {
          // display: none;
          width: 24px;
          height: 24px;
          background-color: transparent;
          font-size: 24px;
        }
        .el-carousel__arrow--left {
          left: 269.5px;
        }
        .el-carousel__arrow--right {
          right: 269.5px;
        }
        // .el-carousel-item {
        //   width: 684px;
        //   height: 412px;
        //   display: flex;
        //   justify-content: flex-start;
        //   align-items: center;
        // }
        // .is-active {
        //   width: 684px;
        // }

        .el-carousel__mask {
          background-color: #666666;
          opacity: 0.6;
          z-index: 3;
        }
        .el-carousel__indicators {
          // left: 18px;
          transform: translateX(0);
          // display: flex;
          // justify-content: flex-end;
          // margin-bottom: 24px;
          .el-carousel__indicator {
            width: 44px;
            margin: 0 6px;
          }
          .el-carousel__button {
            width: 44px;
            height: 4px;
            background: rgba(255, 255, 255, 0.2);
          }
          .is-active {
            .el-carousel__button {
              background: #d70c19;
            }
          }
        }
        .el-carousel__indicators--outside {
          position: absolute;
          bottom: 0;
          transform: translateX(-52%);
        }
      }
      // .hot_one {
      //   height: 100%;
      //   background: #f2f2f2;
      //   display: flex;
      //   justify-content: flex-start;
      //   margin-bottom: 20px;
      //   .img {
      //     width: 424px;
      //     height: 318px;
      //     overflow: hidden;
      //     img {
      //       width: 100%;
      //       height: 100%;
      //     }
      //   }
      //   .desc {
      //     width: 318px;
      //     padding: 54px 24px;
      //     .title {
      //       width: 441px;
      //       height: 91px;
      //       font-size: 24px;
      //       font-family: PingFangSC-Regular, PingFang SC;
      //       font-weight: 600;
      //       color: #333333;
      //       line-height: 40px;
      //     }
      //     .author {
      //       height: 22px;
      //       font-size: 16px;
      //       font-family: PingFangSC-Regular, PingFang SC;
      //       font-weight: 400;
      //       color: #999999;
      //       line-height: 22px;
      //       margin-bottom: 7px;
      //     }
      //     .detail {
      //       width: 441px;
      //       height: 90px;
      //       font-size: 16px;
      //       font-family: PingFangSC-Regular, PingFang SC;
      //       font-weight: 400;
      //       color: #666666;
      //       line-height: 30px;
      //     }
      //   }
      // }
      .list {
        height: 74px;
        display: flex;
        flex-wrap: wrap;
        .news {
          width: 430px;
          height: 37px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-right: 53px;
          i {
            display: block;
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-left: 6px solid #d70c19;
            border-bottom: 4px solid transparent;
            margin-right: 10px;
          }
          &:nth-child(2n) {
            margin: 0;
          }
          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid #d8d8d8;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
          &:nth-child(3),
          &:nth-child(4) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            .content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
          .title {
            height: 27px;
            line-height: 27px;
            width: 414px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
    .box_right {
      // border: 1px solid red;
      width: 331px;
      h3 {
        margin-bottom: 14px;
        img {
          width: 22px;
          height: 22px;
          margin-right: 9px;
        }
        .right_title {
          height: 22px;
          font-size: 20px;
          font-weight: 500;
          color: #d70c19;
          line-height: 22px;
        }
      }
      .right_flex {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        .flex_num {
          width: 24px;
          font-size: 20px;
          font-weight: 500;
          color: #999999;
          line-height: 20px;
          margin-right: 6px;
        }
        .one {
          color: #d93639;
        }

        .two {
          color: #f17531;
        }
        .three {
          color: #f8b041;
        }
        .flex_title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          height: 20px;
          width: 315px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            color: #d70c19;
          }
        }
        .hot {
          width: 20px;
          height: 20px;
          background: #d70c19;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>