<template>
  <div class="article-content hand">
    <div class="main-content">
      <div class="title-img">
        <nuxt-link target="_blank"
          :to="{
            path: '/detailPage',
            query: { articleId: info.articleId },
          }"
        >
          <h4 class="title">
            {{ info.title }}
          </h4>
        </nuxt-link>

        <div class="news-tag">
          <!-- <span
            v-if="!(tagBigEnough && index == 1)"
            class="tag"
            v-for="(tag, index) in info.tagList"
            :key="index"
            @click="toTagPage(tag)"
            >{{ tag.name | chinese }}</span
          > -->
          <i class="clock"></i>
          <span class="time">{{ info.publishTime | timeFormater }}</span>
          <!-- <i class="eye" v-if="info.pv > 0"></i>
          <span class="views" v-if="info.pv > 0">{{
            info.pv | viewsFormatter
          }}</span> -->
        </div>
      </div>
      <div class="img_wrapper">
        <img v-lazy="info.coverPicUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import dateformat from "dateformat";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    toTagPage(item) {
      // console.log(item)
      // tagPage ? name = 脂质和代谢 & id=90 & deptID=96101
      this.$router.push({
        path: '/new',
        query: {
          moduleName: "LATEST_NEWS",
          name: item.name,
          id: item.termId,
          deptID: item.departmentId
        }
      })
    }
  },
  computed: {
    tagBigEnough() {
      return this.info.tagList.join('').length > 8 ? true : false
    },
  },
  filters: {
    // 时间格式化
    timeFormater(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
    // pv格式化
    viewsFormatter(pv) {
      if (pv > 10000) {
        let w = parseInt(pv / 10000)
        let q = parseInt(parseInt(pv % 10000) / 1000)

        return q > 0 ? `${w}.${q}万` : `${w}万`
      } else {
        return pv
      }
    },
    chinese(v) {
      if (v.length > 8) {
        return v.substring(0, 8) + '...'
      } else {
        return v
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.article-content {
  width: 100%;
  height: 90px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
  .main-content {
    display: flex;
    justify-content: space-between;
    .title-img {
      width: 72%;
      h4 {
        width: 100%;
        max-height: 52px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }
      h4:hover {
        color: #d70c19;
      }
    }
    .news-tag {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 22px;
      width: 100%;
      margin-bottom: 8px;
      .tag {
        display: block;
        padding: 0 6px;
        height: 22px;
        background: #f2f2f2;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        color: #a7a7a7;
        line-height: 22px;
        margin: 0 3px;
      }
      .tag:nth-child(1) {
        margin-left: 1px;
      }
      .clock {
        width: 14px;
        height: 15px;
        background-image: url("../../assets/images/yxj_home/clock@2x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 2px;
        // margin-left: 2px;
      }
      .time {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 22px;
        margin-right: 6px;
      }
      .eye {
        width: 16px;
        height: 12px;
        background-image: url("../../assets/images/yxj_home/chakan@2x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 2px;
      }
      .views {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 22px;
      }
    }
  }
  .img_wrapper {
    width: 88px;
    height: 65px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
}
</style>