<!--
 * @Author: zhouxiaobo zhouxiaobo@yxj.org.cn
 * @Date: 2021-08-20 17:47:53
 * @LastEditors: zhouxiaobo zhouxiaobo@yxj.org.cn
 * @LastEditTime: 2022-05-09 10:03:20
 * @FilePath: \pc-wesite\components\home\writer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="writer container">
    <div class="title">
      <h4>专栏作家</h4>
      <!-- <button class="check_all">查看全部</button> -->
    </div>
    <div class="carousel">
      <button @click="pre"></button>
      <button @click="next"></button>
      <el-carousel ref="clumns" arrow="never" height="120px" :autoplay="false">
        <el-carousel-item v-for="(group, index) in infoGroup" :key="index">
          <ul class="content">
            <li
              @click="goWritterDetaile(item)"
              class="hand"
              v-for="(item, key) in group"
              :key="key"
            >
              <div class="img_wrapper">
                <img v-lazy="item.avatar" alt="" />
              </div>
              <div class="details">
                <h4>{{ item.name }}</h4>
                <p>
                  {{ item.title }}
                </p>
              </div>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    infoGroup() {
      let group = []
      for (let i = 0; i < this.info.length; i += 4) {
        group.push(this.info.slice(i, i + 4))
      }
      return group
    }
  },
  methods: {
    pre() {
      this.$refs['clumns'].prev()
    },
    next() {
      this.$refs['clumns'].next()
    },
    // 跳转作家详情
    goWritterDetaile(pro) {
      // console.log(pro, '作家信息');
      this.$router.push({ path: '/columnist', query: { proId: pro.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.writer {
  margin-top: 6px;
  padding-top: 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #f2f2f2;
  margin-bottom: 39px;
  .title {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h4 {
      height: 46px;
      font-size: 24px;

      font-weight: 500;
      color: #333333;
      line-height: 46px;
    }
    .check_all {
      height: 31px;
      font-size: 15px;

      font-weight: 500;
      color: #333;
      line-height: 31px;
    }
  }
  .carousel {
    width: 100%;
    height: 170px;
    position: relative;
    button {
      position: absolute;
      color: #333333;
      width: 15px;
      height: 26px;
      z-index: 1000;
      bottom: 0;
    }
    button:nth-child(1) {
      background-image: url("../../assets/images/yxj_home/left.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      left: 472px;
    }
    button:nth-child(2) {
      background-image: url("../../assets/images/yxj_home/right.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 472px;
    }
    /deep/ .el-carousel {
      width: 100%;
      height: 170px;
      padding-bottom: 50px;
      .content {
        li {
          width: 295px;
          height: 120px;
          margin-right: 40px;
          background-color: #fff;
          float: left;
          border-radius: 2px;
          border: 1px solid rgba(210, 210, 210, 0.5);
          display: flex;
          &:hover {
            box-shadow: 0px 2px 15px 0px rgba(210, 210, 210, 0.5);
          }
          .img_wrapper {
            overflow: hidden;
          }
          img {
            width: 117px;
            height: 118px;
          }
          img {
            transition: all 0.5s;
          }
          img:hover {
            transform: scale(1.1, 1.1);
          }
          .details {
            width: 177px;
            height: 118px;
            padding-left: 30px;
            h4 {
              margin-top: 19px;
              margin-bottom: 3px;
              height: 26px;
              font-size: 19px;

              font-weight: 500;
              color: #333333;
              line-height: 26px;
              overflow: hidden;
            }
            p {
              width: 100%;
              height: 27px;
              line-height: 27px;
              font-size: 14px;

              font-weight: 400;
              color: #4a4a4a;
            }
          }
        }
        li:nth-child(4) {
          margin-right: 0;
        }
      }
      .el-carousel__container {
        position: relative;
        height: 170px;
        padding-bottom: 50px;
        .el-carousel__arrow {
          position: absolute;
          bottom: -100px;
        }
      }
      .el-carousel__indicators {
        height: 26px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .el-carousel__indicator {
          padding: 0;
          width: 13px;
          height: 13px;
          background: #ebe9e9;
          border-radius: 50%;
          margin: 0 25px;
          .el-carousel__button {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .is-active {
          background-color: #d93639;
          .el-carousel__button {
            background-color: #d93639;
          }
        }
      }
    }
  }
}
</style>