<template>
  <div class="anchor">
    <div
      class="anchor_item"
      :class="{ is_active: active === index }"
      v-for="(item, index) in tabList"
      :key="index"
      @click="goAnchor(index)"
    >
      <span>{{ item }}</span>
    </div>
    <!-- <div
      class="anchor_item"
      :class="{ is_active: active === 1 }"
      @click="goAnchor(1)"
    >
      <span>特色分类</span>
    </div>
    <div
      class="anchor_item"
      :class="{ is_active: active === 2 }"
      @click="goAnchor(2)"
    >
      <span>精品课程</span>
    </div>
    <div
      class="anchor_item"
      :class="{ is_active: active === 3 }"
      @click="goAnchor(3)"
    >
      <span>精选直播</span>
    </div>
    <div
      class="anchor_item"
      :class="{ is_active: active === 4 }"
      @click="goAnchor(4)"
    >
      <span>医学调研</span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabList: []
    }
  },
  methods: {
    goAnchor(index) {
      this.$emit('anchorChange', index)
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(document.getElementsByClassName("link_title"), '菜单');
      let tabList = Array.from(document.getElementsByClassName("link_title"))
      tabList.forEach(item => {
        this.tabList.push(item.title)
      })
    })
  },

}
</script>

<style lang="scss" scoped>
.anchor {
  position: fixed;
  left: 50%;
  transform: translateX(-792px);
  width: 110px;
  .anchor_item {
    height: 54px;
    background: #f6f6f6;
    justify-content: center;
    display: flex;
    &:last-child {
      span {
        border: none;
      }
    }
    span {
      display: block;
      height: 54px;
      line-height: 54px;
      width: 72px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      border-bottom: 1px solid #d8d8d8;
      cursor: pointer;
    }
  }
  .is_active {
    background: #d70c19 !important;
    span {
      color: #ffffff !important;
      border: none;
    }
  }
}
</style>