<template>
  <div class="classic_case container">
    <div class="title">
      <h4>精彩病例</h4>
      <nuxt-link target="_blank"
        :to="{ path: '/caseHistory', query: { deptID: $route.query.deptID } }"
      >
        <button class="check_all">查看全部</button>
      </nuxt-link>
    </div>
    <HotNews v-if="info.length > 0" :info="info" />
  </div>
</template>


<script>
import HotNews from './hotNews.vue'
export default {
  props: {
    info: {
      type: Array
    }
  },
  components: {
    HotNews
  }
}
</script>

<style lang="scss" scoped>
.classic_case {
  background: #fff;
  margin-bottom: 24px;
  .title {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      height: 46px;
      font-size: 24px;

      font-weight: 500;
      color: #333333;
      line-height: 46px;
    }
    .check_all {
      height: 31px;
      font-size: 15px;

      font-weight: 500;
      color: #333;
      line-height: 31px;
    }
  }
}
</style>