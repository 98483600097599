<template>
  <div class="hot-news" v-if="hotNewsLeft">
    <div class="title-all" v-if="showTitle">
      <h4 class="article-type">{{ nameList[hotNewsLeft.moduleName] }}</h4>
      <nuxt-link target="_blank"
        :to="{
          path: '/frontier',
          query: { moduleName: hotNewsLeft.moduleName },
        }"
      >
        <button class="check-all">查看全部</button>
      </nuxt-link>
    </div>
    
    <div class="content container">
      <div class="img-content hand">
        <img v-lazy="hotNewsLeft && hotNewsLeft.coverPicUrl" alt="" />
      </div>
      <div class="news-main hand">
        <nuxt-link target="_blank"
          :to="{
            path: '/detailPage',
            query: { articleId: hotNewsLeft&&hotNewsLeft.articleId },
          }"
        >
          <h4 class="title">
            {{ hotNewsLeft && hotNewsLeft.title }}
          </h4>
        </nuxt-link>

        <div class="news-tag">
          <!-- <span
            class="tag"
            v-for="(tag, index) in hotNewsLeft.tagList"
            :key="index"
            @click="toTagPage(tag)"
            >{{ tag.name | chinesetag }}</span
          > -->
          <i class="clock"></i>
          <span class="time" v-if="hotNewsLeft">{{
            hotNewsLeft.publishTime | timeFormater
          }}</span>
          <!-- <i v-if="hotNewsLeft.pv > 0" class="eye"></i>
          <span v-if="hotNewsLeft.pv > 0" class="views">{{
            hotNewsLeft.pv | viewsFormatter
          }}</span> -->
        </div>
        <p class="introduce line3" v-if="hotNewsLeft">
          {{ hotNewsLeft.brief | chinese }}

          <nuxt-link target="_blank"
          class="read_all"
            :to="{
              path: '/detailPage',
              query: { articleId: hotNewsLeft.articleId },
            }"
          >阅读全文<i class="el-icon-arrow-right"></i>
          </nuxt-link>
        </p>
      </div>
      <div class="news-lest">
        <article-view
          class="margin-15 article_item"
          v-for="(item, index) in hotNewsRight"
          :key="index"
          :info="item"
        ></article-view>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleView from '../article/articleView'
import dateformat from "dateformat";
export default {
  props: {
    info: {
      type: Array,
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hotNewsLeft: null,
      hotNewsRight: [],
      nameList: { // 模块名称枚举
        'BASIC_MEDICAL': '基础医疗',
        'MEDICAL_FRONTIER': '医学前沿',
        'MEDICAL_HUMANITIES': '医学人文',
        'MEETING_REPORT': '会议报道',
        'POLICY_FINANCE': '政策产经',
        'HOSPITAL_MANAGEMENT': '医院管理',
        'DRUG': '临床用药',
        'CLINICAL_UTILITY': '临床实用'
      }
    }
  },
  mounted() {
    // console.log('this.info-------------------', this.info)
    // console.log(this.info, 'this.info');
    for (let i = 0; i <= 2; i++) {
      if (i === 0 && this.info && this.info[i]) {
        this.hotNewsLeft = this.info[i]
      }
      if (i > 0 && this.info && this.info[i]) {
        this.hotNewsRight.push(this.info[i])
      }
    }
  },

  methods: {
    toTagPage(item) {
      // console.log(item)
      // tagPage ? name = 脂质和代谢 & id=90 & deptID=96101
      this.$router.push({
        path: '/new',
        query: {
          moduleName: "LATEST_NEWS",
          name: item.name,
          id: item.termId,
          deptID: item.departmentId
        }
      })
    }
  },
  components: { ArticleView },
  filters: {
    // 时间格式化
    timeFormater(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
    chinese(v) {
      if (v != '') {
        return v + "..."
      } else {
        return v
      }
    },
    // pv格式化
    viewsFormatter(pv) {
      if (pv > 10000) {
        let w = pv / 10000
        let q = (pv % 1000) / 1000
        return `${w}.${q}万`
      } else {
        return pv
      }
    },
    chinesetag(v) {
      if (v.length > 8) {
        return v.substring(0, 8) + '...'
      } else {
        return v
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-news {
  background-color: #fff;
  margin-bottom: 36px;
  .title-all {
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .article-type {
      height: 31px;
      font-size: 22px;

      font-weight: 500;
      color: #333333;
      line-height: 31px;
    }
    .check-all {
      height: 31px;
      font-size: 15px;

      font-weight: 500;
      color: #333;
      line-height: 31px;
    }
  }
  .content {
    border-bottom: 4px solid #f2f2f2;
    // height: 240px;
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    .img-content {
      width: 272px;
      height: 196px;
      overflow: hidden;
      margin-right: 20px;
      border-radius: 2px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
      }
      img:hover {
        transform: scale(1.1, 1.1);
      }
    }
    .news-main {
      width: 560px;
      height: 100%;
      margin-right: 50px;
      h4 {
        max-height: 62px;
        line-height: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        // line-height: 31px;
        font-size: 18px;

        font-weight: 500;
        color: #333333;
        margin-bottom: 12px;
      }
      h4:hover {
        color: #d70c19;
      }
      .news-tag {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 22px;
        width: 100%;
        margin-bottom: 15px;
        .tag {
          display: block;
          padding: 0 6px;
          height: 22px;
          background: #f2f2f2;
          border-radius: 2px;
          font-size: 12px;

          font-weight: 400;
          color: #a7a7a7;
          line-height: 22px;
          margin: 0 5px;
        }
        .tag:nth-child(1) {
          margin-left: 0;
        }
        .clock {
          width: 14px;
          height: 15px;
          background-image: url("../../assets/images/yxj_home/clock@2x.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 6px;
        }
        .time {
          height: 22px;
          font-size: 12px;

          font-weight: 400;
          color: #a8a8a8;
          line-height: 22px;
          margin-right: 12px;
        }
        .eye {
          width: 16px;
          height: 12px;
          background-image: url("../../assets/images/yxj_home/chakan@2x.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 6px;
        }
        .views {
          height: 22px;
          font-size: 12px;

          font-weight: 400;
          color: #a8a8a8;
          line-height: 22px;
        }
      }
      .introduce {
        height: 90px;
        font-size: 14px;

        font-weight: 400;
        color: #4a4a4a;
        line-height: 30px;
        // letter-spacing: 1px;
        position: relative;
        .read_all {
          position: absolute;
          bottom: 0;
          right: 10px;
          width: 78px;
          height: 30px;
          font-size: 15px;

          font-weight: 500;
          color: #333333;
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        // &::after {
        //   content: "阅读全文 >";
        //   color: #0f66a8;
        //   position: absolute;
        //   right: 0;
        //   bottom: 0;
        //   font-size: 15px;
        //
        //   font-weight: 500;
        // }
      }
    }
    .news-lest {
      width: 30.7%;
      // height: 196px;
    }

    .margin-15 {
      margin-bottom: 15px;
    }
    .article_item:nth-child(2) {
      border-bottom: none;
    }
  }
}
</style>