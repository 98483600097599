<template>
  <div class="news-content container">
    <div class="top-article" v-if="topArticleList.length">
      <ArticleList
        v-for="(item, index) in topArticleList"
        :key="index"
        :info="item"
        :moduleName="item[0].moduleName"
      />
    </div>
    <!-- 广告位 -->
    <MidAd :info1="midAd1" :info2="midAd2" />
    <div>
      <hotNews
        :info="botArticleList[0]"
        v-if="botArticleList.length === 1"
        showTitle
      />
    </div>
    <div class="bottom-article" v-if="botArticleList.length > 1">
      <ArticleList
        v-for="(item, index) in botArticleList"
        :key="index"
        :info="item"
        :moduleName="item[0].moduleName"
      />
      <a :href="midAd3 && midAd3.link"
        ><img v-if="!isShow" class="ad" v-lazy="midAd3 && midAd3.coverUrl" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
import ArticleList from '../article/articleList.vue'
import MidAd from '../home/midAd.vue'
import hotNews from '../home/hotNews.vue'
export default {
  props: {
    info: {
      type: Array
    }
  },
  // MEETING_REPORT
  data() {
    return {
      midAd1: null,
      midAd2: null,
      midAd3: null,
      topArticleList: [],
      botArticleList: [],
      isShow: false
    }
  },
  components: { ArticleList, MidAd, hotNews },
  mounted() {
    this.getMidAd()
    // 动态拆分为上下两个部分
    this.info && this.info.forEach((item, index) => {
      if (item[0].moduleName == "MEETING_REPORT") {
        this.isShow = true
      }

      index < 3 ? this.topArticleList.push(item) : this.botArticleList.push(item)

    })
  },
  methods: {
    getMidAd() {
      // 首页中间广告-1
      this.$axios.$post('/ysz-content/web/adverts/queryAdvertsByNode', {
        departmentId: this.$route.query.deptID || 0,
        node1: '首页',
        node2: '分隔位1'
      }).then(({ body: res }) => {
        this.midAd1 = res
      })
        .catch(err => {
          console.log(err);
        })

      // 首页中间广告-2
      this.$axios.$post('/ysz-content/web/adverts/queryAdvertsByNode', {
        departmentId: this.$route.query.deptID || 0,
        node1: '首页',
        node2: '分隔位2'
      }).then(({ body: res }) => {
        this.midAd2 = res
      })
        .catch(err => {
          console.log(err);
        })

      this.$axios.$post('/ysz-content/web/adverts/queryAdvertsByNode', {
        departmentId: this.$route.query.deptID || 0,
        "node1": "首页",
        "node2": "会议报道补位"
      }).then(({ body: res }) => {
        this.midAd3 = res
      })
        .catch(err => {
          console.log(err);
        })

    },
  }
}
</script>

<style lang="scss" scoped>
.news-content {
  // padding-bottom: 25px;
  margin-bottom: 40px;
  // max-height: 1250px;
  // border-bottom-width: 1px;
  // border-bottom-style: solid;
  // border-bottom-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  .top-article {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .bottom-article {
    display: flex;
    justify-content: flex-start;
  }
  .ad {
    width: 400px;
    height: 464.5px;
  }
}
</style>