<template>
  <div class="article-list" :class="{ fullpage: fullPage }">
    <div class="title-all">
      <h4 class="article-type">{{ nameList[moduleName] }}</h4>
      <nuxt-link target="_blank"
        :to="{
          path: '/frontier',
          query: { moduleName, deptID: this.$route.query.deptID },
        }"
      >
        <button class="check-all">查看全部</button>
      </nuxt-link>
    </div>
    <ArticleView
      class="margin-bottom-9"
      v-for="(item, index) in info"
      :key="index"
      :info="item"
    />
  </div>
</template>

<script>
import ArticleView from './articleView.vue'
export default {
  props: {
    info: {
      type: Array
    },
    fullPage: {
      type: Boolean,
      default: false
    },
    moduleName: {
      type: String
    }
  },
  data() {
    return {
      nameList: { // 模块名称枚举
        'BASIC_MEDICAL': '基础医疗',
        'MEDICAL_FRONTIER': '医学前沿',
        'MEDICAL_HUMANITIES': '医学人文',
        'MEETING_REPORT': '会议报道',
        'POLICY_FINANCE': '政策产经',
        'HOSPITAL_MANAGEMENT': '医院管理',
        'DRUG': '临床用药',
        'CLINICAL_UTILITY': '临床实用'
      }
    }
  },
  methods: {
  },
  components: { ArticleView }
}
</script>

<style lang="scss" scoped>
.article-list {
  width: 30.76%;
  margin-right: 50px;
  .title-all {
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .article-type {
      height: 31px;
      font-size: 22px;

      font-weight: 500;
      color: #333333;
      line-height: 31px;
    }
    .check-all {
      height: 31px;
      font-size: 15px;

      font-weight: 500;
      color: #333;
      line-height: 31px;
    }
  }
  .margin-bottom-9 {
    margin-bottom: 18px;
  }
  .margin-bottom-9:last-child {
    margin-bottom: 10px;
  }
}
.article-list:nth-child(3n) {
  margin-right: 0;
}
</style>