<template>
  <div title="医米调研" class="link_title classic_case container">
    <!-- <h4>医米调研</h4> -->
    <!-- <div class="content">
      <ul>
        <li
          @click="goDetail(item.articleId)"
          class="hand"
          v-for="(item, index) in infoTop5"
          :key="index"
        >
          <div class="course-view">
            <div class="img_content">
              <div class="img_wrapper">
                <img v-lazy="item.minpicUrl" alt="" />
              </div>
            </div>
            <div class="title-btn">
              <h5 class="line2">{{ item.title }}</h5>
            </div>
            <div class="time_date">{{ item.publishTime | formatTime }}</div>
          </div>
        </li>
        <li>
          <nuxt-link :to="{ path: '/survey', query: { activeIndex: 3 } }">
            <button class="more-course">
              <span>更多案例</span>
            </button>
          </nuxt-link>
        </li>
      </ul>
    </div> -->
    <div class="title">
      <h4>医米调研</h4>
      <nuxt-link :to="{ path: '/survey', query: { activeIndex: 3 } }">
        <button class="check_all hand">
          <span class="text">查看全部</span><span class="icon"></span>
        </button>
      </nuxt-link>
    </div>
    <div class="cases">
      <div class="left" v-show="info[0]">
        <div class="img">
          <img class="scale" :src="info[0] && info[0].minpicUrl" alt="" />
        </div>
        <div class="desc">
          <div
            class="main_title hand line_two hoverRed"
            @click="goDetail(info[0].articleId)"
          >
            {{ info[0] && info[0].title }}
          </div>
          <div class="time">
            {{ info[0] && info[0].publishTime | formatTime }}
          </div>
          <div class="detail line_three">
            {{ info[0] && info[0].brief }}
          </div>
          <div class="read_all hand" @click="goDetail(info[0].articleId)">
            <span>阅读全文</span> <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="art" v-show="info[1]">
          <div
            class="right_title line_two hand hoverRed"
            @click="goDetail(info[1].articleId)"
          >
            {{ info[1] && info[1].title }}
          </div>
          <div class="time">
            {{ info[1] && info[1].publishTime | formatTime }}
          </div>
        </div>
        <div class="dvider"></div>
        <div class="art" v-show="info[2]">
          <div
            class="right_title line_two hand"
            @click="goDetail(info[2].articleId)"
          >
            {{ info[2] && info[2].title }}
          </div>
          <div class="time">
            {{ info[2] && info[2].publishTime | formatTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateformat from "dateformat";
export default {
  props: {
    info: {
      type: Array
    }
  },
  components: {

  },
  mounted() {
    console.log(this.info, '===========');
  },
  filters: {
    formatTime(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
  },
  methods: {
    goDetail(articleId) {
      this.$router.push({ path: '/detailPage', query: { articleId, isSurvey: true, researchLocation: 1 } })
    }
  },
  computed: {
    infoTop5() {
      return this.info.filter((item, index) => index < 5)
    },
  }
}
</script>

<style lang="scss" scoped>
.classic_case {
  background: #fff;
  padding-bottom: 32px;
  margin-bottom: 40px;
  border-bottom: 2px solid #f2f2f2;
  .title {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      height: 30px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 30px;
    }
    // .check_all {
    //   height: 31px;
    //   font-size: 15px;

    //   font-weight: 500;
    //   color: #333;
    //   line-height: 31px;
    // }
    .check_all {
      height: 19px;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      line-height: 19px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        margin-right: 2px;
      }
      .icon {
        display: block;
        width: 15px;
        height: 15px;
        background: url("../../assets/images/yxj_home/checkAll.png") no-repeat;
        background-size: contain;
      }
    }
  }

  .cases {
    height: 195px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 858px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 251px;
        height: 189px;
        margin-right: 24px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .desc {
        width: 583px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .main_title {
          height: 62px !important;
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          line-height: 31px;
          margin-bottom: 4px;
        }
        .time {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          margin-bottom: 5px;
        }
        .detail {
          height: 90px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
        .read_all {
          height: 30px;
          font-size: 15px;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          text-align: right;
        }
      }
    }
    .right {
      width: 362px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .art {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .right_title {
          height: 52px !important;
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          line-height: 26px;
          margin-bottom: 10px;
        }
        .time {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }
      .dvider {
        height: 2px;
        background-color: #f2f2f2;
        margin: 17px 0;
      }
    }
  }

  // h4 {
  //   height: 30px;
  //   margin-bottom: 26px;
  //   font-size: 24px;
  //   font-weight: 600;
  //   color: #333333;
  //   line-height: 30px;
  // }
  // .content {
  //   height: 240px;
  //   width: 100%;
  //   ul {
  //     display: flex;
  //     justify-content: flex-start;
  //     li {
  //       width: 218px;
  //       border-radius: 2px;
  //       margin-right: 30px;
  //       &:hover {
  //         box-shadow: 0px 2px 15px 0px rgba(210, 210, 210, 0.5);
  //       }
  //     }
  //     li:last-child {
  //       width: 50px;
  //       height: 240px;
  //       border-radius: 4px;
  //     }
  //     .more-course {
  //       width: 59px;
  //       height: 240px;
  //       background: #f9f9f9;
  //       &:hover {
  //         background-color: white;
  //       }
  //       span {
  //         display: block;
  //         width: 16px;
  //         height: 88px;
  //         font-size: 16px;
  //         line-height: 22px;
  //         margin: 0 auto;

  //         font-weight: 400;
  //         color: #4a4a4a;
  //       }
  //     }
  //   }
  //   .course-view {
  //     border: 1px solid #f0f0f0;
  //     overflow: hidden;
  //     .img_content {
  //       position: relative;
  //       .img_wrapper {
  //         width: 218px;
  //         height: 136px;
  //         overflow: hidden;
  //       }
  //       img {
  //         width: 218px;
  //         height: 136px;
  //         border-radius: 2px;
  //       }
  //       img {
  //         transition: all 0.5s;
  //       }
  //       img:hover {
  //         transform: scale(1.1, 1.1);
  //       }
  //     }
  //   }
  //   .title-btn {
  //     height: 48px;
  //     margin: 14px 0 10px 0;
  //     padding: 0 16px;
  //     h5 {
  //       height: 48px;
  //       font-size: 18px;
  //       font-weight: 600;
  //       color: #333333;
  //       line-height: 24px;
  //     }
  //   }
  //   .time_date {
  //     height: 16px;
  //     padding: 0 16px;
  //     font-size: 14px;
  //     font-weight: 400;
  //     color: #999999;
  //     line-height: 16px;
  //     margin-bottom: 18px;
  //   }
  // }
}
</style>