<template>
  <div class="qr_code">
    <div class="left">
      <div class="text left_text">下载医学界医生站</div>
      <div class="code_img init_img">
        <img src="../../../assets/images/yxj_home/ysz.png" alt="" />
      </div>
    </div>
    <div class="mid"></div>
    <div class="right">
      <div class="text">关注医生站公众号</div>
      <div class="code_img">
        <img src="../../../assets/images/yxj_home/gzh.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.qr_code {
  width: 331px;
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  .mid {
    width: 1px;
    height: 166px;
    border-right: 1px dotted #999;
  }
  .left,
  .right {
    width: 128px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .text {
    width: 128px;
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
  }
  .left_text {
    margin-bottom: 26px;
  }
  .code_img {
    width: 128px;
    height: 128px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .init_img {
    width: 110px;
    height: 110px;
  }
}
</style>