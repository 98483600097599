<template>
  <div class="meet_report">
    <!-- 栏目标题 -->
    <div class="title">
      <h5>{{ data.title }}</h5>
      <div class="check_all hand" @click="checAll(data.title)">
        <span class="text">查看全部</span><span class="icon"></span>
      </div>
    </div>

    <div class="meets">
      <div
        class="meet hand"
        v-for="item in data.newsList"
        :key="item.articleId"
        @click="goDetail(item.articleId)"
      >
        <div class="img">
          <img class="scale" :src="item.coverPicUrl" alt="" />
        </div>
        <div class="meet_title line_two hoverRed">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    goDetail(articleId) {
      this.$router.push({ path: '/detailPage', query: { articleId } })
    },
    checAll(title) {
      this.$router.push({ path: '/articelList', query: { title, postition: '1', deptID: this.department ? this.department.dptid : 0 } })
    },
  },
  computed: {
    ...mapGetters(['department'])
  },

}
</script>

<style lang="scss" scoped>
.meet_report {
  width: 913px;
  .title {
    height: 40px;
    line-height: 38px;
    border-bottom: 2px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    h5 {
      height: 30px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 30px;
    }
    .check_all {
      height: 19px;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      line-height: 19px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        margin-right: 2px;
      }
      .icon {
        display: block;
        width: 15px;
        height: 15px;
        background: url("../../../assets/images/yxj_home/checkAll.png")
          no-repeat;
        background-size: contain;
      }
    }
  }
  .meets {
    width: 100%;
    height: 221px;
    display: flex;
    justify-content: space-between;
    .meet {
      width: 210px;
      height: 221px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img {
        width: 210px;
        height: 158px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .meet_title {
        height: 54px;
        line-height: 27px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
      }
    }
  }
}
</style>