<template>
  <div class="border">
    <div v-for="(item, i) in info" :key="i">
      <div v-if="i < 2" class="border_flex">
        <nuxt-link target="_blank"
          :to="{
            path: '/detailPage',
            query: { articleId: item.articleId },
          }"
        >
          <img v-lazy="item.coverPicUrl" alt=""
        /></nuxt-link>
        <nuxt-link target="_blank"
          :to="{
            path: '/detailPage',
            query: { articleId: item.articleId },
          }"
        >
          <div class="flex_box">
            <div class="box_title">
              {{ item.title }}
            </div>
            <div class="box_time">{{ item.publishTime | timeFormater }}</div>
          </div>
        </nuxt-link>
      </div>
      <nuxt-link target="_blank"
        :to="{
          path: '/detailPage',
          query: { articleId: item.articleId },
        }"
        v-else
      >
        <div class="smalltitle">
          {{ item.title }}
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import dateformat from "dateformat";
export default {
  props: {
    info: {
      type: Array,
    },
  },
  mounted() {
  },
  filters: {
    // 时间格式化
    timeFormater(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.border {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  .border_flex {
    display: flex;
    margin-bottom: 23px;
    width: 400px;
    img {
      width: 100px;
      height: 75px;
      margin-right: 19px;
      cursor: pointer;
    }
    .flex_box {
      .box_title {
        width: 280px;
        height: 52px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        &:hover {
          color: #d70c19;
        }
      }
      .box_time {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 17px;
        text-align: right;
        padding-right: 10px;
      }
    }
  }
  .smalltitle {
    width: 400px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #d70c19;
    }
  }
}
</style>