<template>
  <div class="carousel">
    <button @click="pre"></button>
    <button @click="next"></button>
    <div class="content container">
      <el-carousel
        ref="live"
        :autoplay="false"
        arrow="never"
        :interval="4000"
        type="card"
        height="410px"
        indicator-position="outside"
        @change="carouselChange"
      >
        <el-carousel-item v-for="(course, index) in info" :key="index">
          <nuxt-link target="_blank"
            :to="{
              path: '/details/video',
              query: { vdoid: course.contentId },
            }"
          >
            <img v-lazy="course.coverUrl" :alt="course.title" />
            <div v-if="index === currentIndex" class="live_content">
              <i class="icon"></i>
              <!-- <h4 class="title line1">{{ course.title }}</h4> -->
              <!-- <p class="desc line3">{{ course.brief }}</p> -->
            </div>
          </nuxt-link>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0
    }
  },
  props: {
    info: {
      type: Array
    }
  },
  methods: {
    pre() {
      this.$refs['live'].prev()
    },
    next() {
      this.$refs['live'].next()
    },
    carouselChange(index) {
      this.currentIndex = index
    },
  }
}
</script>
     
<style lang="scss" scoped>
.carousel {
  position: relative;
  height: 628px;
  background-color: #022253;
  margin-bottom: 40px;
  button {
    position: absolute;
    z-index: 99;
    width: 43px;
    height: 59px;
  }
  button:nth-child(1) {
    left: 255px;
    top: 262px;
    background-image: url("../../assets/images/yxj_home/lunbo_left.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  button:nth-child(2) {
    right: 255px;
    top: 262px;
    background-image: url("../../assets/images/yxj_home/lunbo_right.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/.el-carousel {
    width: 1234px;
    height: 474px;
    .el-carousel__container {
      .el-carousel__item {
        a {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .live_content {
          position: absolute;
          height: 156px;
          bottom: 0;
          // margin-bottom: 28px;
          width: 100%;
          // padding: 0 65px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.41) 100%
          );

          .icon {
            position: absolute;
            right: 100px;
            bottom: 78px;
            display: block;
            width: 42px;
            height: 42px;
            margin-bottom: 13px;
            background-image: url("../../assets/images/yxj_home/live_icon.png");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .title {
            width: 100%;
            height: 29px;
            font-size: 21px;

            font-weight: 500;
            color: #ffffff;
            line-height: 29px;
          }
          .desc {
            width: 100%;
            height: 72px;
            font-size: 15px;

            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
          }
        }
      }
      .is-active {
        width: 730px;
        transform: translateX(252px) scale(1) !important;
      }
    }
    .el-carousel__indicators {
      height: 26px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .el-carousel__indicator {
        padding: 0;
        width: 13px;
        height: 13px;
        background: #ebe9e9;
        border-radius: 50%;
        margin: 0 25px;
        .el-carousel__button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .is-active {
        background-color: #d93639;
        .el-carousel__button {
          background-color: #d93639;
        }
      }
    }
  }
}
</style>