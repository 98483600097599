<template>
  <div id="spc_cate" title="特色分类" class="link_title mid_content container">
    <div class="content1 flex_content">
      <div class="left">
        <!-- 科室 -->
        <div class="departments">
          <div class="seven">
            <div
              class="main_dpt top_dpt hand hoverRed"
              :class="{ isActive: activeDept == item.id }"
              v-for="(item, index) in topSevenDpt"
              :key="index"
            >
              {{ item.name }}
            </div>
            <div class="more top_dpt" @click="toggelLeftDpt">
              <span class="more_text hand">更多</span>
              <span class="more_toggel hand"></span>
              <div class="left_dpt" v-if="left_dpt_show">
                <div
                  class="left_departments hand hoverRed"
                  v-for="(dpt, index) in leftDpt"
                  :key="index"
                  @click="goTagPage(dpt, 2)"
                >
                  {{ dpt.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 对应科室资讯 -->

        <!-- 医学前沿 -->
        <depart :data="contentList[0]" />
      </div>
      <div class="right">
        <rightClumn :data="sideContentList[0]" />
      </div>
    </div>
    <div class="content2 flex_content">
      <div class="left">
        <depart :data="contentList[1]" />
      </div>
      <div class="right">
        <rightClumn :data="sideContentList[1]" />
      </div>
    </div>

    <div class="content3 flex_content">
      <div class="left">
        <meetReport :data="contentList[2]" />
      </div>
      <div class="right">
        <rightClumn :data="sideContentList[2]" />
      </div>
    </div>

    <div class="content4 flex_content">
      <div class="left">
        <depart :data="contentList[3]" />
      </div>
      <div class="right">
        <rightClumn :data="sideContentList[3]" />
      </div>
    </div>

    <div class="content5 flex_content">
      <div class="left">
        <depart :data="contentList[4]" />
      </div>
      <div class="right">
        <qrCode />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import depart from "./midContent/depart.vue";
import rightClumn from "./midContent/rightClumn.vue";
import meetReport from "./midContent/meetReport.vue";
import qrCode from "./midContent/qrCode.vue";
export default {
  components: { depart, rightClumn, meetReport, qrCode },
  props: {
    clumns: {
      type: Array,
      default: () => []
    },
    contentList: {
      type: Array,
      default: () => []
    },
    sideContentList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      topSevenDpt: [],
      leftDpt: [],
      activeDept: 0,
      left_dpt_show: false
    }
  },
  created() {
    this.topSevenDpt = this.clumns.slice(0, 8)
    if (this.department) {
      this.clumns.forEach((clumn, index) => {
        if (clumn.id == this.department.dptid && index > 7) {
          this.topSevenDpt[7] = clumn
        }
      })
      this.activeDept = this.department.dptid
    }
    this.leftDpt = this.clumns.slice(8, this.clumns.length)
  },
  computed: {
    ...mapGetters(['department'])
  },
  methods: {
    ...mapMutations(['setepartment']),
    goTagPage(item, position) {
      this.setepartment({ dptid: item.id, name: item.name })
      this.$emit('dptChange', item.id)
      if (position == 2) {
        this.leftDpt.forEach((clumn, index) => {
          if (clumn.id == item.id) {
            let cur = this.topSevenDpt[7]
            this.topSevenDpt[7] = clumn
            this.leftDpt[index] = cur
          }
        })
        this.activeDept = this.department.dptid

      }
      this.activeDept = item.id
    },
    go() {
      this.setepartment(null)
    },
    toggelLeftDpt() {
      if (this.leftDpt.length <= 0) return
      this.left_dpt_show = !this.left_dpt_show
      document.getElementsByClassName('more_toggel')[0].style.transform = this.left_dpt_show ? 'rotate(180deg)' : 'rotate(360deg)';
    }
  },
  mounted() {
    let main_dpt = document.getElementsByClassName('main_dpt')
    if (main_dpt.length > 0) {
      for (let i = 0; i < main_dpt.length; i++) {
        main_dpt[i].addEventListener('mouseover', (e) => {
          this.topSevenDpt.forEach((item, index) => {
            if (index == i && this.activeDept != item.id) {
              this.activeDept = item.id
              this.$emit('dptChange', item.id)
            }
          })
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.flex_content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}
.mid_content {
  background-color: #fff;
  .content5 {
    margin-bottom: 40px;
  }
  .left {
    width: 913px;
    .departments {
      height: 54px;
      background-color: #f6f6f6;
      line-height: 54px;
      margin-bottom: 20px;

      .seven {
        position: relative;
        height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .top_dpt {
        position: relative;
        height: 54px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 54px;
        padding: 0 18px;
        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 1px;
          height: 14px;
          border: 1px solid #d8d8d8;
          right: 0;
          top: 20px;
        }
        &:last-child::after {
          display: none;
        }
      }
      .isActive {
        font-size: 18px;
        font-weight: 600;
        color: #d70c19;
      }
      .left_dpt {
        position: relative;
        z-index: 100;
        width: 913px;
        background-color: #f6f6f6;
        position: absolute;
        top: 54px;
        left: -835px;
        padding: 20px 30px 0 30px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .left_departments {
          width: 121.85px;
          height: 20px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
      .more {
        color: #999999;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &::after {
          display: none;
        }
        // &:hover {
        //   .left_dpt {
        //     display: flex !important;
        //     justify-content: flex-start;
        //     flex-wrap: wrap;
        //   }
        // }
        .more_toggel {
          display: block;
          width: 10px;
          height: 4px;
          background: url("../../assets/images/yxj_home/more_dpt.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
}
</style>