<template>
  <div class="advertisement">
    <div class="content container">
      <a target="_blank" :href="info && info.link">
        <img v-lazy="info && info.coverUrl" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.advertisement {
  background-color: #fff;
  margin-bottom: 19px;
  .content {
    height: 100px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100px;
    }
    img {
      transition: all 0.3s;
    }
    img:hover {
      transform: scale(1.02, 1.02);
    }
  }
}
</style>