<template>
  <div id="home" class="home">
    <!-- 锚点导航 -->
    <Anchor
      v-show="anchorShow"
      @anchorChange="anchorChange"
      :active="anchorIndex"
    />
    <!-- <Banner :info="bannerInfo.data[0]" :isJy="isJy" @emitJyBtn="jyBtn" /> -->
    <!-- <Activity :info="bannerDown.data" /> -->
    <!-- <VideoIndex :info="videoInfo.data[0]" :list="renzheng.data" /> -->

    <!-- 顶部广告 -->
    <!-- <TopAd v-if="ads" :info="ads" /> -->
    <!-- 图片登陆 -->
    <!-- <ImgLogin v-if="!$store.getters.isLogin" /> -->
    <!-- <div v-if="$store.getters.isLogin" style="margin-bottom: 38px"></div> -->
    <!-- <no-ssr> -->
    <Consult :topNewsList="topNewsData" :hot-list="hotList"></Consult>
    <!-- </no-ssr> -->
    <!-- 栏目 -->
    <!-- <Clumns :clumns="tagList" /> -->

    <!-- v2.3.0迭代 -->
    <midContent
      :clumns="tagList"
      :contentList="mainNewsData"
      :sideContentList="rightNewsData"
      @dptChange="onDptChange"
    />
    <!-- <no-ssr> -->
    <!-- 热点资讯 -->
    <!-- <HotNews v-show="hotNews.length > 0 && !isHome" :info="hotNews" /> -->
    <!-- </no-ssr> -->
    <!-- 六种资讯 -->
    <!-- <NewsContent :info="newsList" /> -->
    <!-- 专栏作家 -->
    <!-- <Writer v-if="clumnsWriter.length > 0" :info="clumnsWriter" /> -->
    <!-- 轮播 -->
    <!-- <Carousel v-if="courser.length > 0" :info="courser" /> -->
    <!-- v2.3.0迭代 -精品课程推荐 -->
    <CourseRecom v-if="courser.length > 0" :info="courser" />

    <!-- v2.3.0迭代 -精品直播推荐 -->
    <LiveRecom v-if="liveList.length > 0" :info="liveList" />

    <!-- 精彩病例 v2.3.0移除 -->
    <!-- <ClassicDisease
      v-if="wonderful_example.length > 0"
      :info="wonderful_example"
    /> -->

    <!-- 医米调研案例 v2.3.0迭代 -->
    <ResearchCase v-if="researchList.length > 0" :info="researchList" />
    <!-- 指南解读 v2.3.0移除 -->
    <!-- <GuidRead v-if="guidAnalysis.length > 0" :info="guidAnalysis" /> -->

    <!-- 医学院排行榜 v2.3.0移除 -->
    <!-- <HospitalRank v-if="medicalRankList.length > 0" :info="medicalRankList" /> -->

    <!-- 品牌会议 -->
    <!-- <BrandMeet /> -->

    <!-- 专家 -->
    <Expert v-if="professorList.length > 0" :info="professorList" />
    <!-- <Ask :active="true" /> -->
  </div>
</template>

<script>
import DataUtil from "~/utils/time";
if (process.browser) {
  // 在这里根据环境引入wow.js
  var { WOW } = require("wowjs");
}
import Consult from "@/components/home/consult.vue";
import TopAd from "../components/home/topAd.vue";
import ImgLogin from "../components/home/imgLogin.vue";
import Clumns from "../components/home/clumns.vue";
import midContent from "../components/home/midContent.vue";
import HotNews from "../components/home/hotNews.vue";
import NewsContent from "../components/home/newsContent.vue";
import Writer from "../components/home/writer.vue";
import Carousel from "../components/home/carousel.vue";
import LiveCourse from "../components/home/liveCourse.vue";
import ClassicDisease from "../components/home/classicDisease.vue";
import ResearchCase from "../components/home/researchCase.vue";
import HospitalRank from "../components/home/hospitalRank.vue";
import GuidRead from "../components/home/guidRead.vue";
import BrandMeet from "../components/home/brandMeet.vue";
import Expert from "../components/home/expert.vue";
import LiveRecom from "../components/home/liveRecom.vue";
import CourseRecom from "../components/home/courseRecom.vue";
import Anchor from "../components/home/anchor.vue";

export default {
  name: "home",
  serverCacheKey() {
    // 2分钟更新频率
    return "home" + Math.floor(Date.now() / (1000 * 120));
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  // head() {
  //   return {
  //     // title: this.seo.seoTitle || '医学界医生站-医生职业的加油站',
  //     // title: '医学界-助力医生临床决策和职业成长',
  //     meta: [
  //       { hid: 'keyword', name: 'keyword', content: '医学,医学资讯,新药信息,在线CME课程,医学会议,用药查询,医学公式,临床指南,职称考试,会员服务,医学界' },
  //       {
  //         hid: 'description',
  //         name: 'description',
  //         content: '医学界助力医生临床决策和职业成长-为医生提供优质的医学内容、实用的医学工具、同行交流的平台与职业成长服务。助力创新药卓越营销-为创新药的循证医学信息传播、创新医生教育和数字化营销，提供一站式解决方案。助力基层医院学科建设-为专科建设赋能，帮助基层（地/县级）医疗机构提升综合服务能力。'
  //       }
  //     ],
  //   }
  // },
  data() {
    return {
      mounted: false,
      anchorIndex: 0,
      anchorShow: false,
      lastOffsetTop: 2845,
      // isHome: true
    };
  },
  watchQuery: ["deptID"],
  async asyncData({ $axios, query, app }) {
    // console.log('query---------------', query)
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>【1】');
    // 首页广告
    // let { body: ads } = await $axios.$post('/ysz-content/web/adverts/queryAdvertsByNode', {
    //   departmentId: query.deptID || 0,
    //   node1: '首页',
    //   node2: '顶部广告位'
    // })
    console.log(123456);
    try {
      var start = new Date();
      var res = await Promise.all([
        // 获取科室
        $axios.$post("/ysz-content/web/home/news/queryCategoryList"),
        $axios.$post("/ysz-content/homeCourse/list", {
          departmentId: query.deptID || 0,
        }),
        $axios.$post("/ysz-live/homeLive/list", {
          departmentId: query.deptID || 0,
        }),
        $axios.$post("/ysz-user/cpProfessor/info", {
          departmentId: query.deptID || 0,
        }),
        // 热榜资讯
        $axios.$post("/ysz-content/web/home/news/queryHotNewsList"),
        // 案例调研
        $axios.post("/ysz-content/articles/queryResearchByPage", {
          pageNum: 1,
          pageSize: 4,
          researchLocation: 1, // 1.案例展示，2.新闻报告
        }),
        // 顶部资讯
        $axios.$post("/ysz-content/web/home/news/getNewsModuleData", {
          categoryId: query.deptID || 0,
          position: "HOME_PAGE_TOP",
        }),
        // 首页主资讯
        $axios.$post("/ysz-content/web/home/news/getNewsModuleData", {
          categoryId: query.deptID || 0,
          position: "HOME_PAGE_MAIN_NEWS",
        }),
        // 首页右侧资讯
        $axios.$post("/ysz-content/web/home/news/getNewsModuleData", {
          categoryId: query.deptID || 0,
          position: "HOME_PAGE_RIGHT_SIDE_NEWS",
        }),
        // 根据频道id查询专家列表
        $axios.$post("/ysz-content/webProfessor/queryWebProfessorList", {
          categoryId: query.deptID || 0,
        }),
        // 获取栏目配置
        $axios.$post("/ysz-content/column/queryColumnList", {}),
        // 时间因子
        $axios.$post("/ysz-content/articles/getArticleSEO", {}),
      ]);

      // 热榜资讯
      // var hotNews = await $axios.$post('/ysz-content/web/home/news/queryHotNewsList')
      // 案例调研
      // var researchRes = await $axios.post('/ysz-content/articles/queryResearchByPage', {
      //   "pageNum": 1,
      //   "pageSize": 4,
      //   "researchLocation": 1, // 1.案例展示，2.新闻报告
      // })
      // 顶部资讯
      // var topNewsData = await $axios.$post('/ysz-content/web/home/news/getNewsModuleData', {
      //   categoryId: query.deptID || 0,
      //   position: 'HOME_PAGE_TOP'
      // })

      // 首页主资讯
      // var mainNewsData = await $axios.$post('/ysz-content/web/home/news/getNewsModuleData', {
      //   categoryId: query.deptID || 0,
      //   position: 'HOME_PAGE_MAIN_NEWS'
      // })

      // 首页右侧资讯
      // var rightNewsData = await $axios.$post('/ysz-content/web/home/news/getNewsModuleData', {
      //   categoryId: query.deptID || 0,
      //   position: 'HOME_PAGE_RIGHT_SIDE_NEWS'
      // })

      // 根据频道id查询专家列表
      // var professorList = await $axios.$post('/ysz-content/webProfessor/queryWebProfessorList', {
      //   categoryId: query.deptID || 0,
      // })

      var tagList = res[0].body || [];
      var courser = res[1].body || [];
      var liveList = res[2].body || [];
      var expert = res[3].body || {};
      var hotList = res[4].body.news || [];
      var researchList = res[5].data.body.data || [];
      var topNewsData = res[6].body.moduleList[0].newsList || [];
      var mainNewsData = res[7].body.moduleList || [];
      var rightNewsData = res[8].body.moduleList || [];
      var professorList = res[9].body.list || [];
      var columnList = res[10].body.list || [];
      var timeFactorRes = res[11].body || {};
      var columnDetail =
        columnList.find((i) => {
          return i.url == process.env.webUrl + "/";
        }) || {};
      var timeFactorData = {
        ["@context"]: "https://ziyuan.baidu.com/contexts/cambrian.jsonld",
        ["@id"]: `${process.env.webUrl}/}`,
        title: `${timeFactorRes.title}`,
        pubDate: `${DataUtil.formatSeoTime(timeFactorRes.artDate)}`,
        upDate: `${DataUtil.formatSeoTime(timeFactorRes.updateTime)}`,
      };
      app.head.title = columnDetail.title || "医学界医生站-医生职业的加油站";
      app.head.meta.find((i) => i.name == "keyword").content =
        columnDetail.keyword ||
        "医学,医学资讯,新药信息,在线CME课程,医学会议,用药查询,医学公式,临床指南,职称考试,会员服务,医学界";
      app.head.meta.find((i) => i.name == "description").content =
        columnDetail.description ||
        "医学界助力医生临床决策和职业成长-为医生提供优质的医学内容、实用的医学工具、同行交流的平台与职业成长服务。助力创新药卓越营销-为创新药的循证医学信息传播、创新医生教育和数字化营销，提供一站式解决方案。助力基层医院学科建设-为专科建设赋能，帮助基层（地/县级）医疗机构提升综合服务能力。";
      console.log("服务端请求耗时---------------", new Date() - start);
    } catch (error) {
      console.log(error);
    }
    return {
      tagList,
      courser,
      liveList,
      expert,
      hotList,
      researchList,
      topNewsData,
      mainNewsData,
      rightNewsData,
      professorList,
      timeFactorData
    };
  },
  mounted() {
    if (location.host === 'www.yxj.org.cn') {
      console.log(location.host,'符合统计');
      try {
        let ld_script = document.getElementById('ld_script')
        if (ld_script) {
          ld_script.innerHTML=`${JSON.stringify(this.timeFactorData)}`
        } else {
          ld_script = document.createElement('script')
          ld_script.id = 'ld_script'
          ld_script.type = 'application/ld+json'
          ld_script.innerHTML = `${JSON.stringify(this.timeFactorData)}`
          document.head.appendChild(ld_script)
        }

      } catch (error) {
      }
    }    
    this.scrollForAnchor();
    this.initAnchorShowStatu();
    window.addEventListener("resize", this.initAnchorShowStatu);
    this.globalSt =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.addEventListener("scroll", this.scroll);

    this.lastOffsetTop =
      document.querySelectorAll(".link_title")[
        document.querySelectorAll(".link_title").length - 1
      ].offsetTop - 64;

    if (process.browser) {
      // 在页面mounted生命周期里面 根据环境实例化WOW
      let newsList = 0;
      new WOW({
        live: false,
        callback: function (e) {
          if (e.getAttribute("data-type") == "newsList") {
            newsList += 50;
            e.style.animationDelay = newsList + "ms";
          }
        },
      }).init();
    }

    // let cBox = document.querySelectorAll('.prog_list .item')

    // let img = new Image()

    // img.src = cBox[0].querySelectorAll('img')[0].getAttribute('src')

    // img.addEventListener('load', this.curResize(cBox))

    // window.addEventListener('resize', this.curResize(cBox))
    if (!localStorage.getItem("loginInfo")) {
      this.isLogin = true;
    }
  },
  methods: {
    initAnchorShowStatu() {
      let w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (
        w < 1584 ||
        document.getElementById("home").getBoundingClientRect().top >= 104
      ) {
        this.anchorShow = false;
      } else {
        this.anchorShow = true;
      }
    },
    async onDptChange(dptId) {
      // 首页主资讯
      var mainNewsData = await this.$axios.$post(
        "/ysz-content/web/home/news/getNewsModuleData",
        {
          categoryId: dptId || 0,
          position: "HOME_PAGE_MAIN_NEWS",
        }
      );
      // 根据频道id查询专家列表
      var professorList = await this.$axios.$post(
        "/ysz-content/webProfessor/queryWebProfessorList",
        {
          categoryId: dptId || 0,
        }
      );
      this.mainNewsData = mainNewsData.body.moduleList;
      this.professorList = professorList.body.list;
    },
    anchorChange(index) {
      this.anchorIndex = index;
      // document.getElementsByClassName("link_title")[index].scrollIntoView();
      let target =
        document.getElementsByClassName("link_title")[index].offsetTop +
        189 -
        64; // 64为顶部导航栏
      document.documentElement.scrollTop = target;
      // this.goTo(target)  // 步长滚动，不均匀
    },
    //页面滚动时触发
    scrollForAnchor() {
      // scrollTop为显示屏顶部与整个文档顶部间的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      // 获取含样式类 class="link_title" 的所有元素，得到一个数组（列表）
      let titleList = document.querySelectorAll(".link_title");
      // 将每个目标元素顶部与整个文档顶部的距离，以及显示屏顶部与整个文档顶部间的距离都存入到一个数组中
      let offsetTopList = [scrollTop];
      let navHeight = 225;
      titleList.forEach((item, index) => {
        if (index == 1) {
          offsetTopList.push(
            item.offsetTop +
              navHeight +
              (item.offsetHeight - window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight)
          );
        } else {
          offsetTopList.push(item.offsetTop + navHeight);
        }
      });
      // 对数组进行排序（默认从小到大）
      offsetTopList.sort((a, b) => {
        return a - b;
      });

      // 当第一个目标元素滚入屏幕后，查询当前滚动的位置在数组中的下标，对应的便是需高亮显示的锚点下标
      if (scrollTop >= offsetTopList[0]) {
        this.anchorIndex =
          offsetTopList.indexOf(scrollTop) == offsetTopList.length - 1
            ? offsetTopList.length - 2
            : offsetTopList.indexOf(scrollTop);
      }
      let wStatu =
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) >= 1584;

      if (
        document.getElementById("home").getBoundingClientRect().top <= 104 &&
        document.getElementById("home").getBoundingClientRect().top >=
          -this.lastOffsetTop &&
        wStatu
      ) {
        this.anchorShow = true;
      } else {
        this.anchorShow = false;
      }
    },
    goTo(target) {
      var scrollT =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollT > target) {
        var timer = setInterval(function () {
          var scrollT =
            document.body.scrollTop || document.documentElement.scrollTop;
          var step = Math.floor(-scrollT / 30);
          document.documentElement.scrollTop = document.body.scrollTop =
            step + scrollT;
          if (scrollT <= target) {
            document.body.scrollTop = document.documentElement.scrollTop =
              target;
            clearTimeout(timer);
          }
        }, 10);
      } else if (scrollT == 0) {
        var timer = setInterval(function () {
          var scrollT =
            document.body.scrollTop || document.documentElement.scrollTop;
          var step = Math.floor((300 / 3) * 0.7);
          document.documentElement.scrollTop = document.body.scrollTop =
            step + scrollT;
          console.log(scrollT);
          if (scrollT >= target) {
            document.body.scrollTop = document.documentElement.scrollTop =
              target;
            clearTimeout(timer);
          }
        }, 10);
      } else if (scrollT < target) {
        var timer = setInterval(function () {
          var scrollT =
            document.body.scrollTop || document.documentElement.scrollTop;
          var step = Math.floor(scrollT / 30);
          document.documentElement.scrollTop = document.body.scrollTop =
            step + scrollT;
          if (scrollT >= target) {
            document.body.scrollTop = document.documentElement.scrollTop =
              target;
            clearTimeout(timer);
          }
        }, 10);
      } else if (target == scrollT) {
        return false;
      }
    },
    jyBtn(bool) {
      this.isJy = bool;
    },
    scroll() {
      this.globalSt =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollForAnchor();
      // if (this.globalSt > document.querySelector('.activity').offsetTop) {
      //   if (!this.isJy) this.isJy = true
      // }
    },
    curResize(cBox) {
      let max2 = [];
      for (let i = 0; i < cBox.length; i++) {
        cBox[i].querySelector(".left").style.minHeight = 0 + "px";
        cBox[i].querySelector(".right").style.minHeight = 0 + "px";
        max2[i] = Math.max(
          cBox[i].querySelector(".left").clientHeight,
          cBox[i].querySelector(".right").clientHeight
        );
        cBox[i].querySelector(".left").style.minHeight = max2[i] + "px";
        cBox[i].querySelector(".right").style.minHeight = max2[i] + "px";
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
    window.removeEventListener("resize", this.curResize);
  },
  components: {
    TopAd,
    Clumns,
    HotNews,
    NewsContent,
    LiveCourse,
    ClassicDisease,
    HospitalRank,
    GuidRead,
    BrandMeet,
    Expert,
    Carousel,
    Writer,
    ImgLogin,
    midContent,
    Consult,
    LiveRecom,
    CourseRecom,
    ResearchCase,
    Anchor,
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
}
</style>