var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hotNewsLeft)?_c('div',{staticClass:"hot-news"},[(_vm.showTitle)?_c('div',{staticClass:"title-all"},[_c('h4',{staticClass:"article-type"},[_vm._v(_vm._s(_vm.nameList[_vm.hotNewsLeft.moduleName]))]),_vm._v(" "),_c('nuxt-link',{attrs:{"target":"_blank","to":{
        path: '/frontier',
        query: { moduleName: _vm.hotNewsLeft.moduleName },
      }}},[_c('button',{staticClass:"check-all"},[_vm._v("查看全部")])])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content container"},[_c('div',{staticClass:"img-content hand"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.hotNewsLeft && _vm.hotNewsLeft.coverPicUrl),expression:"hotNewsLeft && hotNewsLeft.coverPicUrl"}],attrs:{"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"news-main hand"},[_c('nuxt-link',{attrs:{"target":"_blank","to":{
          path: '/detailPage',
          query: { articleId: _vm.hotNewsLeft&&_vm.hotNewsLeft.articleId },
        }}},[_c('h4',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.hotNewsLeft && _vm.hotNewsLeft.title)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"news-tag"},[_c('i',{staticClass:"clock"}),_vm._v(" "),(_vm.hotNewsLeft)?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeFormater")(_vm.hotNewsLeft.publishTime)))]):_vm._e()]),_vm._v(" "),(_vm.hotNewsLeft)?_c('p',{staticClass:"introduce line3"},[_vm._v("\n        "+_vm._s(_vm._f("chinese")(_vm.hotNewsLeft.brief))+"\n\n        "),_c('nuxt-link',{staticClass:"read_all",attrs:{"target":"_blank","to":{
            path: '/detailPage',
            query: { articleId: _vm.hotNewsLeft.articleId },
          }}},[_vm._v("阅读全文"),_c('i',{staticClass:"el-icon-arrow-right"})])],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"news-lest"},_vm._l((_vm.hotNewsRight),function(item,index){return _c('article-view',{key:index,staticClass:"margin-15 article_item",attrs:{"info":item}})}),1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }