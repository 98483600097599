<template>
  <div class="img_login">
    <div class="content container">
      <img
        class="right_bg"
        src="../../assets/images/yxj_home/home_banner_login.png"
        alt=""
      />
      <h3>医学界-服务医者改善医疗</h3>
      <h4>您的医学资讯、临床工具和继续教育的一站式成长平台</h4>
      <button @click="login('register')">免费注册</button>
      <p>已经是会员？<span class="hand" @click="login('login')">登录</span></p>
    </div>

    <Login @loginSuccess="loginSuccess" ref="login" />
  </div>
</template>

<script>
import Login from "../../components/user/login.vue";
export default {
  name: "ImgLogin",
  components: { Login },
  methods: {
    login(type) {
      // if (type == 'login') {
      //   this.$refs.login.tabIndex = 1
      // } else {
      //   this.$refs.login.tabIndex = 0
      // }
      this.$refs.login.show()
    },
    loginSuccess() {
      this.getUserInfo()
    },
    async getUserInfo() {
      if (!localStorage.getItem('loginInfo')) {
        return
      }
      try {
        var loginInfo = JSON.parse(localStorage.getItem('loginInfo'))

        var res = await this.$axios(
          {
            method: 'post',
            url: process.env.baseUrl + 'ysz-auth/auth/info',
            data: {},
            headers: {
              token: loginInfo && loginInfo.access_token ? loginInfo.access_token : null
            }
          })
        // console.log(res)
        if (res.data && res.data.code === 200) {
          this.userInfo = res.data.body
          localStorage.setItem('userInfo', JSON.stringify(res.data.body))
          window.location.reload()
        } else {
          Message({
            type: 'error',
            message: '获取用户信息失败，删除缓存重新登录'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>


<style lang="scss" scoped>
.img_login {
  position: relative;
  width: 100%;
  height: 234px;
  background: linear-gradient(270deg, #e5e5e5 0%, #f1f1f1 100%);
  margin-bottom: 30px;
  .right_bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 39%;
    height: 234px;
  }
  .content {
    height: 100%;
    padding-top: 26px;
    h3 {
      margin-bottom: 20px;
      text-indent: -9999px;
      width: 66px;
      height: 28px;
      padding-left: 66px;
      background-image: url("../../assets/images/yxj_home/yxj_icon.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    h4 {
      height: 38px;
      font-size: 30px;

      font-weight: 500;
      color: #333333;
      line-height: 38px;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }
    button {
      width: 132px;
      height: 44px;
      background: #d70c19;
      border-radius: 2px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 44px;
      margin-bottom: 9px;
      border-radius: 2px;
    }
    p {
      height: 22px;
      font-size: 16px;

      font-weight: 400;
      color: #333333;
      line-height: 22px;
      span {
        color: #b7282d;
      }
    }
  }
}
</style>