<template>
  <div class="clumns-tab">
    <div class="content container">
      <!-- 栏目 -->
      <!-- <div class="title">
        <i></i>
        <h2>{{ department && department.name }}</h2>
      </div> -->

      <!-- 专题类型 -->
      <div class="clumns">
        <ul>
          <li>
            <nuxt-link target="_blank" to="/novelCoronavirus">
              新冠学术前线
            </nuxt-link>
          </li>
          <li :class="!$route.query.deptID ? 'focus_line' : ''">
            <nuxt-link target="_blank" to="/" @click="go">
              <!-- <button></button> -->
              首页
            </nuxt-link>
          </li>
          

          <!-- <div class="hot_recom">热门频道：</div> -->
          <li
            v-for="(item, index) in clumns"
            :key="index"
            :class="$route.query.deptID == item.dptid ? 'focus_line' : ''"
          >
            <no-ssr>
              <a href="javascript:;" @click="goTagPage(item)">
              {{ item.name }}
              </a>
            </no-ssr>
          </li>

        </ul>
        <nuxt-link target="_blank" to="/special">
          <button class="check-all"><i class="icon"></i>全部</button>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  props: {
    clumns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // currentDpt: null,
      clumnsList: []
    }
  },
  computed: {
    ...mapGetters(['department'])
  },
  created() {
    // this.currentDpt = this.$route.query.deptID || 0
    // this.clumns.forEach(ele => {
    //   if (ele.coreFlag == true) {
    //     this.clumnsList.push(ele)
    //     return this.clumnsList
    //   }
    // })
    // console.log("xqq", this.clumnsList);
    // this.clumnsList = this.clumnsList.slice(0, 8)
  },
  methods: {
    ...mapMutations(['setepartment']),
    goTagPage(item) {
      // console.log(item, '知识标签');
      this.currentDpt = item.dptid
      // if (item.titleType === 'DEPARTMENT') {
      this.setepartment({ dptid: item.dptid, name: item.name })
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.deptID = item.dptid
      newQuery.change = 1
      this.$router.push({
        // path: this.$route.path,
        path: '/',
        query: newQuery,
      })
      // } else {
      //   this.$router.push({
      //     name: 'tagPage', query: { name: item.name, id: item.typeId }
      //   })
      // }

    },
    go() {
      this.setepartment(null)
    },
  }
}
</script>

<style lang="scss" scoped>
.clumns-tab {
  background-color: #fff;
  height: 60px;
  .title {
    height: 28px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    i {
      display: block;
      width: 23px;
      height: 32px;
      margin: 0 12px 0 4px;
      background-image: url("../../assets/images/yxj_home/hart.svg");
    }
    h2 {
      height: 28px;
      font-size: 28px;

      font-weight: 500;
      color: #333333;
      line-height: 28px;
    }
  }
  .clumns {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-top-width: 1px;
    // border-top-style: solid;
    // border-top-color: #f2f2f2;
    // border-bottom-width: 1px;
    // border-bottom-style: solid;
    // border-bottom-color: #f2f2f2;
    .check-all {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .icon {
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../assets/images/yxj_home/home_icon_all@2x.png")
          no-repeat;
        background-size: contain;
        margin-right: 6px;
      }
      height: 31px;
      font-size: 16px;
      font-weight: 400;
      color: #666;
      line-height: 31px;
    }
    ul {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .hot_recom {
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        font-weight: 400;
        color: #4a4a4a;
        margin-left: 14px;
      }
      li {
        position: relative;
        height: 60px;
        display: flex;
        padding: 0 11px 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .focus_line {
        position: relative;
        button {
          color: #333333;
          font-weight: 500;
        }
        &::after {
          display: block;
          content: "";
          width: 28px;
          height: 2px;
          background-color: #d7111e;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      // li::after {
      //   position: absolute;
      //   right: 0;
      //   top: 5px;
      //   content: "";
      //   display: block;
      //   width: 2px;
      //   height: 14px;
      //   background-color: #333333;
      // }

      li:first-child {
        padding: 0;
        margin-right: 38px;
        a {
          color: #333;
          font-weight: 600;
          position: relative;
          &:hover {
            color: #d70c19;
          }
          &::after {
            position: absolute;
            top: -2px;
            right: -22px;
            display: block;
            content: "";
            width: 24px;
            height: 12px;
            background: url("../../assets/images/yxj_home/home_hot01_icon@2x.png")
              no-repeat;
            background-size: contain;
          }
        }
      }
      li:last-child {
        button {
          &::after {
            display: none;
          }
        }
      }
      a {
        // margin: 0 15px;
        position: relative;
        padding: 2px 5px;
        height: 24px;
        font-size: 18px;
        font-weight: 400;
        color: #4a4a4a;
        line-height: 18px;
        border-radius: 2px;
        &:hover {
          color: #d70c19;
        }
        &::after {
          position: absolute;
          right: -11px;
          top: 7px;
          display: block;
          content: "";
          width: 2px;
          height: 14px;
          background-color: #666666;
        }
      }
    }
  }
}
</style>