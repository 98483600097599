<template>
  <div class="sideNews">
    <div class="title">
      <h5>{{ data.title }}</h5>
      <div class="checkall hand" @click="checAll(data.title)">
        <span class="text">查看全部</span><span class="icon"></span>
      </div>
    </div>

    <div
      class="top_news hand"
      :class="{ title_mb_more: data.title == '新冠学术前线' }"
      @click="goDetail(data.newsList[0].articleId)"
    >
      <div class="img">
        <img class="scale" :src="data.newsList[0].coverPicUrl" alt="" />
      </div>
      <div class="right">
        <div class="news_title line_three hoverRed">
          {{ data.newsList[0].title }}
        </div>
        <div class="time">{{ data.newsList[0].publishTime | formatTime }}</div>
      </div>
    </div>

    <div
      class="news_left line_one hand hoverRed"
      :class="{
        mb5_more: data.title == '医学人文' || data.title == '顶刊精要',
      }"
      v-for="(item, index) in bottomNews"
      :key="index"
      @click="goDetail(item.articleId)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
import dateformat from "dateformat";
import { mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  filters: {
    formatTime(time) {
      let nowYear = Number(new Date().getFullYear())
      let timeYear = Number(new Date(time * 1000).getFullYear())

      if (timeYear == nowYear) {
        return dateformat(time * 1000, 'mm月dd日')
      } else {
        return dateformat(time * 1000, 'yyyy年mm月dd日')
      }
    },
  },
  computed: {
    ...mapGetters(['department']),
    bottomNews() {
      return this.data.newsList.slice(1, this.data.newsList.length)
    },
  },
  methods: {
    goDetail(articleId) {
      this.$router.push({ path: '/detailPage', query: { articleId } })
    },
    checAll(title) {
      if (title == '新冠学术前线') {
        this.$router.push('/novelCoronavirus')
      } else {
        this.$router.push({ path: '/articelList', query: { title, postition: '2', deptID: this.department ? this.department.dptid : 0 } })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.sideNews {
  width: 331px;
  .title {
    height: 40px;
    line-height: 38px;
    border-bottom: 2px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    h5 {
      height: 30px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 30px;
    }
    .checkall {
      height: 19px;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      line-height: 19px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        margin-right: 2px;
      }
      .icon {
        display: block;
        width: 15px;
        height: 15px;
        background: url("../../../assets/images/yxj_home/checkAll.png")
          no-repeat;
        background-size: contain;
      }
    }
  }
  .top_news {
    width: 331px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .img {
      width: 134px;
      height: 101px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 180px;
      height: 101px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .news_title {
        height: 78px;
        line-height: 26px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
      }
      .time {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }
  }
  .title_mb_more {
    margin-bottom: 32px;
  }
  .news_left {
    width: 324px;
    height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 18px;
    &:last-child {
      margin: 0;
    }
  }
  .mb5_more {
    margin-bottom: 23px;
  }
}
</style>