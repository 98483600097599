var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border"},_vm._l((_vm.info),function(item,i){return _c('div',{key:i},[(i < 2)?_c('div',{staticClass:"border_flex"},[_c('nuxt-link',{attrs:{"target":"_blank","to":{
          path: '/detailPage',
          query: { articleId: item.articleId },
        }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.coverPicUrl),expression:"item.coverPicUrl"}],attrs:{"alt":""}})]),_vm._v(" "),_c('nuxt-link',{attrs:{"target":"_blank","to":{
          path: '/detailPage',
          query: { articleId: item.articleId },
        }}},[_c('div',{staticClass:"flex_box"},[_c('div',{staticClass:"box_title"},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"box_time"},[_vm._v(_vm._s(_vm._f("timeFormater")(item.publishTime)))])])])],1):_c('nuxt-link',{attrs:{"target":"_blank","to":{
        path: '/detailPage',
        query: { articleId: item.articleId },
      }}},[_c('div',{staticClass:"smalltitle"},[_vm._v("\n        "+_vm._s(item.title)+"\n      ")])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }