<template>
  <div class="live-courser container border-bottom">
    <h4>直播公开课</h4>
    <div class="content">
      <ul>
        <li class="hand" v-for="(item, index) in info" :key="index">
          <nuxt-link target="_blank"
            :to="{
              path: '/details/live',
              query: { subjectId: item.subjectId },
            }"
          >
            <div class="course-view">
              <div class="img_content">
                <i class="icon hand"></i>
                <span class="statu">{{ item.liveStatus }}</span>
                <div class="img_wrapper">
                  <img v-lazy="item.coverUrl" alt="" />
                </div>
              </div>
              <div class="title-btn">
                <h5 class="line2">{{ item.title }}</h5>
              </div>
            </div>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link target="_blank" to="/live">
            <button class="more-course">
              <span>更多课程</span>
            </button>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    info: {
      type: Array,
    }
  },
  mounted() {
    // console.log(33333, this.info);
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.live-courser {
  max-height: 328px;
  margin-bottom: 20px;
  h4 {
    height: 46px;
    margin-bottom: 22px;
    font-size: 24px;

    font-weight: 500;
    color: #333333;
    line-height: 46px;
  }
  .content {
    height: 260px;
    width: 100%;
    ul {
      display: flex;
      justify-content: flex-start;
      li {
        width: 218px;
        border-radius: 2px;
        margin-right: 30px;
        &:hover {
          box-shadow: 0px 2px 15px 0px rgba(210, 210, 210, 0.5);
        }
      }
      li:last-child {
        width: 50px;
        height: 220px;
        border-radius: 4px;
      }
      .more-course {
        width: 100%;
        height: 100%;
        background: #f9f9f9;
        &:hover {
          background-color: white;
        }
        span {
          display: block;
          width: 16px;
          height: 88px;
          font-size: 16px;
          line-height: 22px;
          margin: 0 auto;

          font-weight: 400;
          color: #4a4a4a;
        }
      }
    }
    .course-view {
      .img_content {
        position: relative;
        .icon {
          display: block;
          width: 26px;
          height: 26px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-image: url("../../assets/images/yxj_home/live_couser_show.png");
        }
        .statu {
          position: absolute;
          top: 8px;
          right: 10px;
          padding: 0 9px 0 7px;
          display: inline-block;
          height: 21px;
          border-radius: 4px;
          border: 1px solid #ffffff;
          font-size: 14px;

          font-weight: 400;
          color: #ffffff;
          line-height: 19px;
        }
        .img_wrapper {
          width: 218px;
          height: 136px;
          overflow: hidden;
        }
        img {
          width: 218px;
          height: 136px;
          border-radius: 2px;
        }
        img {
          transition: all 0.5s;
        }
        img:hover {
          transform: scale(1.1, 1.1);
        }
      }
    }
    .title-btn {
      height: 86px;
      border: 1px solid #f0f0f0;
      h5 {
        width: 171px;
        height: 48px;
        font-size: 16px;

        font-weight: 500;
        color: #333333;
        line-height: 24px;
        margin: 16px auto;
        margin-bottom: 0;
      }
    }
  }
}
</style>