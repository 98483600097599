<template>
  <div class="experts container">
    <!-- <h4>专家委员会</h4> -->
    <!-- <div class="content">
      <ul>
        <li v-for="(item, index) in info" :key="index">
          <div class="main_content">
            <div class="avatar">
              <img v-lazy="item.headImg" alt="" />
            </div>
            <div class="expert_name">{{ item.name }}</div>
            <div class="expert_title">{{ titleList(item.title)[0] }}</div>
            <p class="detail line4_four">
              <span v-html="item.introduce"></span>
            </p>
          </div>
        </li>
        <li>
          <button class="more-course">
            <span>更多专家持续加入中</span>
          </button>
        </li>
      </ul>
    </div> -->

    <el-carousel height="210px">
      <el-carousel-item v-for="item in info" :key="item.id">
        <!-- 头像 -->
        <div class="expert_img">
          <img v-lazy="item.headImg" alt="" />
        </div>
        <!-- 介绍 -->
        <div class="desc">
          <div class="name_title">
            <div class="name">{{ item.name }}</div>
            <p class="title">
              <span
                v-for="(title, index) in titleList(item.title)"
                :key="index"
                >{{ title }}</span
              >
            </p>
          </div>
          <no-ssr>
            <p class="detail line4_four">
              <span v-html="item.introduce"></span>
            </p>
          </no-ssr>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    titleList(title = '') {
      return title.split(',')
    }
  },
}
</script>

<style lang="scss" scoped>
.experts {
  width: 100%;
  height: 210px;
  background-color: #fff;
  margin-bottom: 80px;
  box-shadow: 0px 2px 15px 0px rgba(210, 210, 210, 0.5);
  border: 1px solid #f0f0f0;
  h4 {
    height: 30px;
    margin-bottom: 26px;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
  }
  // .content {
  //   width: 100%;
  //   ul {
  //     display: flex;
  //     justify-content: flex-start;
  //     li {
  //       border-radius: 2px;
  //       margin-right: 30px;
  //     }
  //     .more-course {
  //       width: 59px;
  //       height: 300px;
  //       background: #f9f9f9;
  //       span {
  //         display: block;
  //         width: 16px;
  //         line-height: 100%;
  //         font-size: 16px;
  //         line-height: 22px;
  //         margin: 0 auto;
  //         font-weight: 400;
  //         color: #4a4a4a;
  //       }
  //     }
  //     .main_content {
  //       width: 218px;
  //       border: 1px solid #eee;
  //       padding-top: 19px;
  //       padding-bottom: 20px;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: flex-start;
  //       align-items: center;
  //       .avatar {
  //         width: 102px;
  //         height: 102px;
  //         border-radius: 50%;
  //         overflow: hidden;
  //         margin-bottom: 17px;
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //         img:not([src]) {
  //           opacity: 0;
  //           border: none;
  //           visibility: hidden;
  //           max-width: none;
  //         }
  //       }
  //       .expert_name {
  //         height: 24px;
  //         font-size: 20px;
  //         font-weight: 600;
  //         color: #333333;
  //         line-height: 24px;
  //         margin-bottom: 8px;
  //       }
  //       .expert_title {
  //         height: 14px;
  //         font-size: 14px;
  //         font-weight: 400;
  //         color: #999999;
  //         line-height: 14px;
  //         margin: 14px;
  //       }
  //       .detail {
  //         width: 100%;
  //         padding: 0 16px;
  //         height: 80px;
  //         line-height: 20px;
  //         font-size: 14px;
  //         font-weight: 400;
  //         color: #666666;
  //         display: -webkit-box;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         -webkit-line-clamp: 4;
  //         -webkit-box-orient: vertical;
  //       }
  //     }
  //   }
  // }
  /deep/.el-carousel {
    position: relative;
    width: 100%;
    height: 210px;
    .el-carousel__arrow {
      display: none;
    }
    .el-carousel-item {
      width: 100%;
      height: 210px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .el-carousel__indicators {
      right: 45px;
      transform: translateX(0);
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;
      .el-carousel__indicator {
        width: 8px;
        margin: 0 5px;
      }
      .el-carousel__button {
        width: 8px;
        height: 8px;
        background: #d8d8d8;
        border-radius: 50%;
      }
      .is-active {
        .el-carousel__button {
          background: #d70c19;
        }
      }
    }
  }
  .expert_img {
    float: left;
    width: 200px;
    height: 210px;
    margin-right: 29px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .desc {
    width: 1024px;
    height: 172px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 36px;
    .name_title {
      width: 100%;
      height: 31px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 13px;
      .name {
        height: 31px;
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 31px;
        margin-right: 30px;
      }
      .title {
        height: 22px;
        overflow: hidden;
        span {
          float: left;
          display: block;
          height: 22px;
          font-size: 14px;
          font-weight: 500;
          color: #666;
          line-height: 22px;
          margin-right: 26px;
        }
      }
    }
    .detail {
      width: 100%;
      height: 96px;
      font-size: 14px;
      font-weight: 400;
      color: #666;
      line-height: 24px;
    }
  }
}
</style>